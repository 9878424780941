

































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class General extends Vue {
  private nombreWeb: any = "";
  private aNoticias: any = [];
  private currentPage = 1;
  private total = 0;
  private slide = 1;
  private ultimasNoticias: any = [];

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 8)) {
      this.getInfoNoticia();
    }
  }

  @Watch("$refs")
  private refsWatch(newValue: any) {}

  private mounted() {
    this.getInfoNoticia();
  }

  private getInfoNoticia() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("noticias/general?pagina=" + this.currentPage)
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.aNoticias = res.data.noticias;
        this.ultimasNoticias = res.data.ultimas_noticias;
        // console.log(this.ultimasNoticias);
        // console.log(res.data);
        for (let i = 0; i < this.aNoticias.length; i++) {
          if (
            (this.aNoticias[i].fotos.image_fulltext &&
              this.aNoticias[i].fotos.image_fulltext != "") ||
            (this.aNoticias[i].fotos.image_intro &&
              this.aNoticias[i].fotos.image_intro != "")
          ) {
            this.aNoticias[i].fotos.image_fulltext =
              base + this.aNoticias[i].fotos.image_fulltext;

            this.aNoticias[i].fotos.image_intro =
              base + this.aNoticias[i].fotos.image_intro;
          } else {
            this.aNoticias[
              i
            ].fotos.image_intro = require("@/assets/logos/logo300.png");
            this.aNoticias[
              i
            ].fotos.image_fulltext = require("@/assets/logos/logo300.png");
          }
        }
        for (let i = 0; i < this.ultimasNoticias.length; i++) {
          if (
            this.ultimasNoticias[i].fotos.image_fulltext &&
            this.ultimasNoticias[i].fotos.image_fulltext != ""
          ) {
            this.ultimasNoticias[i].fotos.image_intro =
              base + this.ultimasNoticias[i].fotos.image_intro;
            this.ultimasNoticias[i].fotos.image_fulltext =
              base + this.ultimasNoticias[i].fotos.image_fulltext;
          } else {
            this.ultimasNoticias[
              i
            ].fotos.image_intro = require("@/assets/logos/logo300.png");
            this.ultimasNoticias[
              i
            ].fotos.image_fulltext = require("@/assets/logos/logo300.png");
          }
        }

        this.total = res.data.total;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private fechaPublicacionNoticias(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "ene";
    } else if (mesInicio == 2) {
      mesInicio = "feb";
    } else if (mesInicio == 3) {
      mesInicio = "mar";
    } else if (mesInicio == 4) {
      mesInicio = "abr";
    } else if (mesInicio == 5) {
      mesInicio = "may";
    } else if (mesInicio == 6) {
      mesInicio = "jun";
    } else if (mesInicio == 7) {
      mesInicio = "jul";
    } else if (mesInicio == 8) {
      mesInicio = "ago";
    } else if (mesInicio == 9) {
      mesInicio = "sep";
    } else if (mesInicio == 10) {
      mesInicio = "oct";
    } else if (mesInicio == 11) {
      mesInicio = "nov";
    } else {
      mesInicio = "dic";
    }

    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + ", " + anhoInicio;
    }
  }

  private irNoticia(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Publicacion" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Publicacion",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private irCategoria(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Categoria" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Categoria",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }
}
