





































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class General extends Vue {
  private nombreWeb: any = "";
  private aActividades: any = [];
  private ultimasActividades: any = [];
  private currentPage = 1;
  private total = 0;
  private slideIndex: number = 1;

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 8)) {
      this.getInfoActividad();
    }
  }

  @Watch("$refs")
  private refsWatch(newValue: any) {}

  private mounted() {
    this.getInfoActividad();
    this.autoscroll();
  }

  private getInfoActividad() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("actividades/general?pagina=" + this.currentPage)
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        // console.log(res.data);
        this.ultimasActividades = res.data.ultimas_actividades;
        this.aActividades = res.data.actividades;
        this.total = res.data.total;
        // console.log(this.ultimasActividades);

        for (let i = 0; i < this.aActividades.length; i++) {
          if (this.aActividades[i].foto) {
            this.aActividades[i].foto = base + this.aActividades[i].foto;
          } else {
            this.aActividades[i].foto = require("@/assets/logos/logo300.png");
          }
        }

        for (let i = 0; i < this.ultimasActividades.length; i++) {
          if (this.ultimasActividades[i].foto) {
            this.ultimasActividades[i].foto =
              base + this.ultimasActividades[i].foto;
          } else {
            this.ultimasActividades[
              i
            ].foto = require("@/assets/logos/logo300.png");
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private formatFechaCard(fecha: any) {
    let date = fecha.split(" ", 2);
    let newDate = date[0].split("-");
    return newDate[2] + "-" + newDate[1] + "-" + newDate[0];
  }

  private irActividad(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "PublicacionAct" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "PublicacionAct",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "enero";
    } else if (mesInicio == 2) {
      mesInicio = "febrero";
    } else if (mesInicio == 3) {
      mesInicio = "marzo";
    } else if (mesInicio == 4) {
      mesInicio = "abril";
    } else if (mesInicio == 5) {
      mesInicio = "mayo";
    } else if (mesInicio == 6) {
      mesInicio = "junio";
    } else if (mesInicio == 7) {
      mesInicio = "julio";
    } else if (mesInicio == 8) {
      mesInicio = "agosto";
    } else if (mesInicio == 9) {
      mesInicio = "septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "octubre";
    } else if (mesInicio == 11) {
      mesInicio = "noviembre";
    } else {
      mesInicio = "diciembre";
    }

    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + " " + anhoInicio;
    }
  }

  private fechasEvento(fecha_inicio, fecha_termino) {
    var anho_inicio = fecha_inicio.substring(0, 4);
    var mes_inicio: any = parseInt(fecha_inicio.substring(5, 7), 10);
    var dia_inicio = fecha_inicio.substring(8, 10);

    var anho_termino = fecha_termino.substring(0, 4);
    var mes_termino: any = parseInt(fecha_termino.substring(5, 7), 10);
    var dia_termino = fecha_termino.substring(8, 10);

    if (mes_inicio == 1) {
      mes_inicio = "Enero";
    } else if (mes_inicio == 2) {
      mes_inicio = "Febrero";
    } else if (mes_inicio == 3) {
      mes_inicio = "Marzo";
    } else if (mes_inicio == 4) {
      mes_inicio = "Abril";
    } else if (mes_inicio == 5) {
      mes_inicio = "Mayo";
    } else if (mes_inicio == 6) {
      mes_inicio = "Junio";
    } else if (mes_inicio == 7) {
      mes_inicio = "Julio";
    } else if (mes_inicio == 8) {
      mes_inicio = "Agosto";
    } else if (mes_inicio == 9) {
      mes_inicio = "Septiembre";
    } else if (mes_inicio == 10) {
      mes_inicio = "Octubre";
    } else if (mes_inicio == 11) {
      mes_inicio = "Noviembre";
    } else {
      mes_inicio = "Diciembre";
    }
    if (mes_termino == 1) {
      mes_termino = "Enero";
    } else if (mes_termino == 2) {
      mes_termino = "Febrero";
    } else if (mes_termino == 3) {
      mes_termino = "Marzo";
    } else if (mes_termino == 4) {
      mes_termino = "Abril";
    } else if (mes_termino == 5) {
      mes_termino = "Mayo";
    } else if (mes_termino == 6) {
      mes_termino = "Junio";
    } else if (mes_termino == 7) {
      mes_termino = "Julio";
    } else if (mes_termino == 8) {
      mes_termino = "Agosto";
    } else if (mes_termino == 9) {
      mes_termino = "Septiembre";
    } else if (mes_termino == 10) {
      mes_termino = "Octubre";
    } else if (mes_termino == 11) {
      mes_termino = "Noviembre";
    } else {
      mes_termino = "Diciembre";
    }

    if (mes_inicio == mes_termino) {
      if (dia_inicio == dia_termino) {
        return dia_inicio + " de " + mes_termino;
      } else {
        return dia_inicio + " al " + dia_termino + " de " + mes_termino;
      }
    } else {
      return (
        dia_inicio +
        " de " +
        mes_inicio +
        " al " +
        dia_termino +
        " de " +
        mes_termino
      );
    }
  }

  private irCategoriaActividad(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "CategoriaActividad" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "CategoriaActividad",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
  // Thumbnail image controls
  private currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }
  private showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    if (slides.length > 0) {
      for (i = 0; i < slides.length; i++) {
        (slides[i] as any).style.display = "none";
      }

      (slides[this.slideIndex - 1] as any).style.display = "block";
    }
  }
  private updated() {
    this.showSlides(this.slideIndex);
  }

  private plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  private autoscroll() {
    this.plusSlides(1);
    setTimeout(this.autoscroll, 9000);
  }

  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }
  // Fin Funcio
}
